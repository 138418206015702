import { AccessConsultation, BaseConsultation } from "./BaseConsultation.model";
import { FinaliteMarcheEnum } from "@atline-shared/enums/finaliteMarche.enum";
import * as _ from "lodash";
import * as BaseCnnsultation from './BaseConsultation.model';
import { TypeDume } from "../Dume.model";

export class ConsultationDetailItem extends BaseConsultation {

  private emailsCons!: string;
  private emails_oe!: string;
  private emails_oe_alias!: string;
  private emails_oe_comp!: string;
  private format_reponse!: string;
  private format_signature!: string;
  private structure_interne!: string;
  private errors_consultation!: any;
  private nb_depot!: number;
  private nb_depot_papier!: number;
  private nb_retrait!: number;
  private nb_retrait_papier!: number;
  private cle_dce_published!: string | null;
  private mail_auto!: boolean;
  private signature_obligatoire!: boolean;
  private alreadyPublishedCons!: boolean;
  private cle_pa!: string;
  private cle_annonce!: string | null;
  private cle_groupe!: string;
  private cle_affaire!: string;
  private option_reponse!: number;
  private option_reponse_f!: string;
  private passation!: string;
  private passation_label!: string | null;
  private type_dume!: TypeDume | null;
  private prix_dce!: number;
  private infos_tarifs!: string | null;
  private date_creation!: number;
  private date_creation_f!: string;
  private allotie!: boolean;
  private type_pa!: string;
  private finalite_marche!: FinaliteMarcheEnum;
  private informatiqueCons!: boolean;
  private invisibleCons!: boolean;
  private publicationCons!: boolean;
  private paysCons!: string;
  private libre_ms!: boolean;
  private passe!: string;

  constructor(consultation: any) {    
    super(consultation);
    this.passe = consultation.passe;
    this.cle_pa = consultation.cle_pa;
    this.cle_groupe = consultation.cle_groupe;
    this.cle_affaire = consultation.cle_affaire;
    this.cle_annonce = consultation.cle_annonce;
    this.prix_dce = consultation.prix_dce;
    this.type_pa = consultation.type_pa;
    this.finalite_marche = consultation.finalite_marche;
    this.option_reponse = consultation.option_reponse;
    this.option_reponse_f = !_.isNil(consultation.option_reponse_f) ? !_.isEmpty(consultation.option_reponse_f) ? consultation.option_reponse_f : null : null;
    this.paysCons = !_.isNil(consultation.pays) ? !_.isEmpty(consultation.pays) ? consultation.pays : null : null;
    this.infos_tarifs = !_.isNil(consultation.infos_tarifs) ? !_.isEmpty(consultation.infos_tarifs) ? consultation.infos_tarifs : null : null;
    this.type_dume = !_.isNil(consultation.type_dume) ? !_.isEmpty(consultation.type_dume) ? consultation.type_dume : null : null;
    this.passation = !_.isNil(consultation.passation) ? !_.isEmpty(consultation.passation) ? consultation.passation : null : null;
    this.passation_label = !_.isNil(consultation.passation_label) ? !_.isEmpty(consultation.passation_label) ? consultation.passation_label : null : null;
    this.emailsCons = !_.isNil(consultation.emails) ? !_.isEmpty(consultation.emails) ? consultation.emails : null : null;
    this.emails_oe = !_.isNil(consultation.emails_oe) ? !_.isEmpty(consultation.emails_oe) ? consultation.emails_oe : null : null;
    this.emails_oe_alias = !_.isNil(consultation.emails_oe_alias) ? !_.isEmpty(consultation.emails_oe_alias) ? consultation.emails_oe_alias : null : null;
    this.emails_oe_comp = !_.isNil(consultation.emails_oe_comp) ? !_.isEmpty(consultation.emails_oe_comp) ? consultation.emails_oe_comp : null : null;
    this.format_reponse = !_.isNil(consultation.format_reponse) ? !_.isEmpty(consultation.format_reponse) ? consultation.format_reponse : null : null;
    this.format_signature = !_.isNil(consultation.format_signature) ? !_.isEmpty(consultation.format_signature) ? consultation.format_signature : null : null;
    this.structure_interne = !_.isNil(consultation.structure_interne) ? !_.isEmpty(consultation.structure_interne) ? consultation.structure_interne : null : null;
    this.cle_dce_published = !_.isNil(consultation.cle_dce_published) ? !_.isEmpty(consultation.cle_dce_published) ? consultation.cle_dce_published : null : null;
    this.mail_auto = !_.isNil(consultation.mail_auto) ? (consultation.mail_auto == 1) ? true : false : false;
    this.signature_obligatoire = !_.isNil(consultation.signature_obligatoire) ? (consultation.signature_obligatoire == 1) ? true : false : false;
    this.alreadyPublishedCons = !_.isNil(consultation.alreadyPublished) ? (consultation.alreadyPublished == 1) ? true : false : false;
    this.allotie = !_.isNil(consultation.allotie) ? (consultation.allotie == 1) ? true : false : false;
    this.informatiqueCons = !_.isNil(consultation.informatique) ? (consultation.informatique == 1) ? true : false : false;
    this.invisibleCons = !_.isNil(consultation.invisible) ? (consultation.invisible == 1) ? true : false : false;
    this.publicationCons = !_.isNil(consultation.publication) ? (consultation.publication == 1) ? true : false : false;
    this.libre_ms = !_.isNil(consultation.libre_ms) ? (consultation.libre_ms == 1) ? true : false : false;
    this.nb_depot = consultation.nb_depot;
    this.nb_depot_papier = consultation.nb_depot_papier;
    this.nb_retrait = consultation.nb_retrait;
    this.nb_retrait_papier = consultation.nb_retrait_papier;
    this.etat = consultation.etat;
    this.date_creation = consultation.date_creation;
    this.date_creation_f = consultation.date_creation_f;
    this.errors_consultation = consultation.errors_consultation;
  }


  get cleDcePublished(): string | null {
    return this.cle_dce_published;
  }

  get informatique(): boolean {
    return this.informatiqueCons;
  }

  get pays(): string {
    return this.paysCons;
  }

  get libreMs(): boolean {
    return this.libre_ms;
  }

  get publication(): boolean {
    return this.publicationCons;
  }

  get invisible(): boolean {
    return this.invisibleCons;
  }

  get finaliteMarche(): FinaliteMarcheEnum {
    return this.finalite_marche;
  }

  get typePa(): string {
    return this.type_pa;
  }

  get isAllotie(): boolean {
    return this.allotie;
  }

  get procedure(): string {
    return this.passation;
  }

  get procedureLabel(): string | null {
    return this.passation_label;
  }

  get clePa(): string {
    return this.cle_pa;
  }

  get cleAnnonce(): string | null {
    return this.cle_annonce;
  }

  get cleGroup(): string | null {
    return this.cle_groupe;
  }

  get cleAffaire(): string | null {
    return this.cle_affaire;
  }

  get alreadyPublished(): boolean {
    return this.alreadyPublishedCons;
  }

  get etatCode(): number {
    return this.etat;
  }

  get errorsConsultation(): number {
    return this.errors_consultation;
  }

  get access(): AccessConsultation {
    return {
      isRestreinte: true,
      code: this.passe
    };
  }

  get dume(): Dume {
    return {
      type: this.type_dume,
      id: this.id_dume,
      hasDume: this.is_dume
    }
  }

  get emails(): EmailsConsultation {
    return {
      server: this.emailsCons,
      isAuto: this.mail_auto,
      oe: {
        list: this.emails_oe,
        alias: this.emails_oe_alias,
        comp: this.emails_oe_comp
      }
    }
  }

  get registres(): Registre {
    return {
      depot: {
        electronic: this.nb_depot,
        paper: this.nb_depot_papier
      },
      retrait: {
        electronic: this.nb_retrait,
        paper: this.nb_retrait_papier
      }
    }
  }

  get price(): PriceDce {
    return {
      infos: this.infos_tarifs,
      price: this.prix_dce
    }
  }

  get response(): ResponseConsultation {
    return {
      format: this.format_reponse,
      signFormat: this.format_signature,
      mandatorySign: this.signature_obligatoire,
      electronique: this.reponse_electronique,
      option: this.option_reponse,
      optionFormated: this.option_reponse_f,
      structureInterne: this.structure_interne
    }
  }

  get dateCreation(): DateCreation {
    return {
      dateFormated: this.date_creation_f,
      timestamp: this.date_creation,
    }
  }
}

export interface EmailsConsultation {
  server: string;
  oe: EmailsOeConsultation;
  isAuto: boolean
}

export interface EmailsOeConsultation {
  list: string;
  alias: string;
  comp: string;
}

export interface ResponseConsultation extends BaseCnnsultation.ResponseConsultation {
  format: string;
  signFormat: string;
  mandatorySign: boolean;
  option: number;
  optionFormated: string;
  structureInterne: string;
}

export interface Dume extends BaseCnnsultation.Dume {
  type: TypeDume | null;
}

export interface Registre {
  depot: TypeRegistre;
  retrait: TypeRegistre
}

export interface TypeRegistre {
  electronic: number;
  paper: number
}

export interface PriceDce {
  price: number;
  infos: string | null
}

export interface DateCreation {
  timestamp: number;
  dateFormated: string
}
