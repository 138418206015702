<app-field-edition *ngIf="composition && composition[keyAnc] && languagesFC"
  [label]="'APP.ANNONCES.LANGUAGES.LABEL' | translate"
  [required]="controlIsRequired(formLanguages)" [invalid]="formLanguages.touched && formLanguages.invalid">
  <!-- <div #containerCheckbox
    fxLayout="column wrap"
    [style.gap]="'10px'"
    [style.minHeight]="'200px'">
    <ng-container *ngFor="let lang of languages">
      <app-round-checkbox *ngLet="formLanguages.get(lang) as control"
        [checked]="!!control?.value"
        (checkedChange)="control?.setValue($event);">
        {{ displayNames.of(lang) | titlecase }}
      </app-round-checkbox>
    </ng-container>
  </div> -->
  <div #containerCheckbox fxLayout="row wrap" [style.gap]="'10px'" [style.minHeight]="'200px'">
    <ng-container *ngFor="let lang of languages">
      <app-round-checkbox *ngLet="formLanguages.get(lang) as control" [checked]="formLanguages.get(lang)?.value"
        (changeState)="formLanguages.get(lang)?.setValue($event)">
        {{ displayNames.of(lang) | titlecase }}
      </app-round-checkbox>
    </ng-container>
  </div>
</app-field-edition>
