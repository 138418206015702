<div class="anc-component" *ngIf="composition" gdColumns="1fr 1fr" gdColumns.lt-md="1fr" gdGap="0px 20px">
  <div fxLayout="row" fxLayoutAlign="stretch stretch" fxLayout.lg="column" fxLayoutGap="20px" fxFlex="100">

    <div fxLayout="column" fxFlex="100">
      <!-- Nom Orguanisation -->
      <app-field-edition *ngIf="nameOrguanisationFC && composition.nameOrganisation"
        [required]="controlIsRequired(nameOrguanisationFC)" [label]="'APP.ANNONCES.DATA-CONTACT-POINT.NAME' | translate"
        [invalid]="nameOrguanisationFC.touched && nameOrguanisationFC.invalid">
        <span class="sub-label" sub-label>BT-500-Organization-Company</span>
        <mat-form-field appearance="outline">
          <input matInput type="text" [formControl]="nameOrguanisationFC" />
        </mat-form-field>
      </app-field-edition>
      <!-- Point de contacte -->
      <app-field-edition *ngIf="contactPointFC && composition.contactPoint"
        [required]="controlIsRequired(contactPointFC)"
        [label]="'APP.ANNONCES.DATA-CONTACT-POINT.CONTACT-POINT' | translate"
        [invalid]="contactPointFC.touched && contactPointFC.invalid">
        <span class="sub-label" sub-label>BT-502-Organization-Company</span>
        <mat-form-field appearance="outline">
          <input matInput type="text" [formControl]="contactPointFC" />
        </mat-form-field>
      </app-field-edition>
      <!-- Département -->
      <app-field-edition *ngIf="departementFC && composition.departement  "
        [label]="'APP.ANNONCES.DATA-CONTACT-POINT.DEPT' | translate" [required]="controlIsRequired(departementFC)"
        [invalid]="departementFC.touched && departementFC.invalid">
        <span class="sub-label" sub-label>BT-16-Organization-Company</span>
        <mat-form-field appearance="outline">
          <input matInput type="text" [formControl]="departementFC" />
        </mat-form-field>
      </app-field-edition>
      <!-- Nom de rue -->
      <app-field-edition
        *ngIf="((isObjectValueParamsComp(composition.street) && composition.street.name) || composition.street) && streetNameFC"
        [label]="'APP.ANNONCES.DATA-CONTACT-POINT.ADR1' | translate" [required]="controlIsRequired(streetNameFC)"
        [invalid]="streetNameFC.touched && streetNameFC.invalid">
        <span class="sub-label" sub-label>BT-510(a)-Organization-Company</span>
        <mat-form-field appearance="outline">
          <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
            cdkAutosizeMaxRows="10" [formControl]="streetNameFC">
        </textarea>
        </mat-form-field>
      </app-field-edition>
      <!-- Rue additionnelle -->
      <app-field-edition
        *ngIf="isObjectValueParamsComp(composition.street) && composition.street.additional && streetAdditionalFC"
        [label]="'APP.ANNONCES.DATA-CONTACT-POINT.ADR2' | translate" [required]="controlIsRequired(streetAdditionalFC)"
        [invalid]="streetAdditionalFC.touched && streetAdditionalFC.invalid">
        <span class="sub-label" sub-label>BT-510(b)-Organization-Company</span>
        <mat-form-field appearance="outline">
          <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
            cdkAutosizeMaxRows="10" [formControl]="streetAdditionalFC">
        </textarea>
        </mat-form-field>
      </app-field-edition>
      <!-- Ligne de rue -->
      <app-field-edition *ngIf="isObjectValueParamsComp(composition.street) && composition.street.line && streetLineFC"
        [label]="'APP.ANNONCES.DATA-CONTACT-POINT.ADR3' | translate" [required]="controlIsRequired(streetLineFC)"
        [invalid]="streetLineFC.touched && streetLineFC.invalid">
        <span class="sub-label" sub-label>BT-510(c)-Organization-Company</span>
        <mat-form-field appearance="outline">
          <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
            cdkAutosizeMaxRows="10" [formControl]="streetLineFC">
        </textarea>
        </mat-form-field>
      </app-field-edition>
      <!-- Code postal & Ville -->
      <app-field-edition *ngIf="(composition.zipcode || composition.city) && zipcodeFC && cityFC"
        [label]="composition.zipcode && composition.city ? ('APP.ANNONCES.DATA-CONTACT-POINT.ZIPCODE' | translate) + ' / ' + ('APP.ANNONCES.DATA-CONTACT-POINT.CITY' | translate) : (composition.zipcode ? ('APP.ANNONCES.DATA-CONTACT-POINT.ZIPCODE' | translate) : ('APP.ANNONCES.DATA-CONTACT-POINT.CITY' | translate))"
        [required]="controlIsRequired(zipcodeFC) || controlIsRequired(cityFC)"
        [invalid]="(zipcodeFC.touched || cityFC.touched) && (zipcodeFC.invalid || cityFC.invalid)">
        <span class="sub-label" sub-label>BT-512-Organization-Company/BT-513-Organization-Company</span>
        <div fxLayout="row" fxLayoutGap="10px">
          <mat-form-field appearance="outline" fxFlex="30">
            <input matInput type="text" [formControl]="zipcodeFC" onlyNumber [decimalActive]="false" mask="0{5}" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <input matInput type="text" [formControl]="cityFC" />
          </mat-form-field>
        </div>
      </app-field-edition>
      <!-- Pays -->
      <app-field-edition *ngIf="composition.country && livPaysFC"
        [label]="'APP.ANNONCES.ADDITIONAL-INFOS-EXECUSSION-PLACE.COUNTRY' | translate"
        [required]="controlIsRequired(livPaysFC)" [invalid]="livPaysFC.touched && livPaysFC.invalid">
        <span class="sub-label" sub-label>BT-514-Organization-Company</span>
        <mat-form-field appearance="outline">
          <mat-select [formControl]="livPaysFC">
            <mat-option *ngFor="let country of countries" [value]="country.iso">
              {{ country.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </app-field-edition>
    </div>

    <div fxLayout="column" fxFlex="100">
      <!-- code nuts -->
      <app-field-edition *ngIf="composition?.codeNUTS && codeNutsFC"
        [label]="'APP.ANNONCES.DATA-CONTACT-POINT.CODE_NUTS' | translate" [required]="controlIsRequired(codeNutsFC)"
        [invalid]="codeNutsFC.touched && codeNutsFC.invalid">
        <mat-form-field appearance="outline">
          <input matInput #code type="text" [matAutocomplete]="auto" [formControl]="codeNutsFC"
            (input)="code.value = code.value.toUpperCase()" />
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let item of filterObs | async" [value]="item.code_nuts">
              {{ item.label_nuts }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </app-field-edition>

      <!-- SIRET -->
      <app-field-edition *ngIf="composition.siret && siretFC"
        [label]="'APP.ANNONCES.DATA-CONTACT-POINT.SIRET' | translate" [required]="controlIsRequired(siretFC)"
        [invalid]="siretFC.touched && siretFC.invalid">
        <span class="sub-label" sub-label>BT-501-Organization-Company</span>
        <mat-form-field appearance="outline">
          <input matInput type="text" [formControl]="siretFC"
            mask="000 000 000 00000" />
          <!-- <input matInput type="text" [formControl]="siretFC" onlyNumber [decimalActive]="false"
            mask="000 000 000 00000" /> -->
        </mat-form-field>
      </app-field-edition>
      <!-- Téléphone -->
      <app-field-edition *ngIf="composition.phone && phoneFC"
        [label]="'APP.ANNONCES.DATA-CONTACT-POINT.PHONE' | translate" [required]="controlIsRequired(phoneFC)"
        [invalid]="phoneFC.touched && phoneFC.invalid">
        <span class="sub-label" sub-label>BT-503-Organization-Company</span>
        <mat-form-field appearance="outline">
          <input matInput type="text" [formControl]="phoneFC" prefix="+33 " [mask]="maskPhoneNumber"
            [validation]="true" />
        </mat-form-field>
      </app-field-edition>
      <!-- Fax -->
      <app-field-edition *ngIf="composition.fax && faxFC" [label]="'APP.ANNONCES.DATA-CONTACT-POINT.FAX' | translate"
        [required]="controlIsRequired(faxFC)" [invalid]="faxFC.touched && faxFC.invalid">
        <span class="sub-label" sub-label>BT-739-Organization-Company</span>
        <mat-form-field appearance="outline">
          <input matInput type="text" [formControl]="faxFC" prefix="+33 " [mask]="maskPhoneNumber"
            [validation]="true" />
        </mat-form-field>
      </app-field-edition>
      <!-- Mail -->
      <app-field-edition *ngIf="composition.mail && mailFC" [label]="'APP.ANNONCES.DATA-CONTACT-POINT.MAIL' | translate"
        [required]="controlIsRequired(mailFC)" [invalid]="mailFC.touched && mailFC.invalid">
        <span class="sub-label" sub-label>BT-506-Organization-Company</span>
        <mat-form-field appearance="outline">
          <input matInput type="text" [formControl]="mailFC" />
        </mat-form-field>
      </app-field-edition>
      <!-- Site(s) internet -->
      <app-field-edition *ngIf="composition.webSite && webSiteFC"
        [label]="'APP.ANNONCES.DATA-CONTACT-POINT.SITE-INTERNET' | translate" [required]="controlIsRequired(webSiteFC)"
        [invalid]="webSiteFC.touched && webSiteFC.invalid">
        <mat-form-field appearance="outline">
          <input matInput type="text" [formControl]="webSiteFC" />
        </mat-form-field>
      </app-field-edition>
      <!-- Site du profil du pouvoir adjudicateur -->
      <app-field-edition *ngIf="composition.webSitePA && webSitePaFC"
        [label]="'APP.ANNONCES.DATA-CONTACT-POINT.SITE-INTERNET-PA' | translate"
        [required]="controlIsRequired(webSitePaFC)" [invalid]="webSitePaFC.touched && webSitePaFC.invalid">
        <mat-form-field appearance="outline">
          <input matInput type="text" [formControl]="webSitePaFC" />
        </mat-form-field>
      </app-field-edition>
    </div>

  </div>
</div>