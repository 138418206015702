import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { NgModule } from '@angular/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TranslateModule } from '@ngx-translate/core';

import { FooterModule } from '@atline-shared/components/footer/footer.module';
import { LoaderModule } from '@atline-shared/components/loader/loader.module';
import { NewsletterModule } from '@atline-shared/components/newsletter/newsletter.module';
import { NewTalentModule } from '@atline-shared/components/new-talent/new-talent.module';

import { MaterialModule } from '@atline/core/material/material.module';
import { MarchesSecurisesModule } from '@atline/marches-securises/marches-securises.module';
import { CoreModule } from '@atline/core/core.module';
import { CookiesModule } from '@atline/cookies/cookies.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


registerLocaleData(localeFr, 'fr');

@NgModule({
  // declarations: [AppComponent, RadioGroupComponent],
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    LoaderModule,
    CoreModule,
    MarchesSecurisesModule,
    NewsletterModule,
    FooterModule,
    MaterialModule,
    TranslateModule,
    CookiesModule,
    NewTalentModule,
  ],
  providers: [{ provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: true} }],
  bootstrap: [AppComponent]
})
export class AppModule { }
