<app-dialog [dialogRef]="dialogRef">

    <h1 mat-dialog-title header>{{ data.title | translate }}</h1>

    <form mat-dialog-content body [formGroup]="formGroup">

        <div fxFlex="100" fxLayout="row">
            <label class="nameGroup text-bl">{{data.inputLabel | translate}}</label>
            <mat-form-field appearance="outline" class="full-width">
                <input matInput type="text" formControlName="content" />
            </mat-form-field>
            <!-- <input matInput  matInput type="text"> -->
        </div>
<!-- 
        <div class="bouton-ajout" fxLayoutAlign="center center">
            <button color="primary" mat-raised-button mat-dialog-close [mat-dialog-close]="false"
                (click)="validate()">{{'APP.MARCHES_SECURISES.PA.ADMINISTRATION.GROUPS.BUTTON_AJOUTER' | translate |
                uppercase}}</button>
        </div> -->

    </form>

    <div mat-dialog-actions footer fxLayout="row" fxLayoutAlign="space-between center" class="info-dialog-actions"
        class="full-witdh">
        <button color="primary" mat-stroked-button mat-dialog-close [mat-dialog-close]="false"
            (click)="close()">{{'APP.CLOSE' | translate | uppercase}}</button>
        <button color="primary" mat-raised-button mat-dialog-close [mat-dialog-close]="false"
            (click)="validate()">{{'APP.MARCHES_SECURISES.PA.ADMINISTRATION.GROUPS.BUTTON_AJOUTER' | translate |
            uppercase}}</button>
    </div>

</app-dialog>