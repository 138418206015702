import { EtapeConsultation } from "@atline-shared/enums/EtapeConsultation.emun";
import * as _ from "lodash";
import { DateClotureDefault, DatePublicationDefault, DefaultFieldConsultation } from "./DefaultFieldConsultation.model";


export class BaseConsultation extends DefaultFieldConsultation {
  private cle_dce!: string;
  private cle_etab!: string;
  private objet_affaire!: string;
  private denomination_pa!: string | null;
  private nb_lots!: number;
  private date_cloture_pa!: number;
  private date_cloture_pa_f!: string;
  private date_cloture_pa_f_s!: string;
  private date_cloture_f!: string;
  private date_cloture_f_s!: string;
  private date_cloture_user?: number;
  private date_cloture_user_f?: string;
  private date_cloture_user_f_s?: string;
  private date_publication!: number;
  private criteres_environnementaux!: boolean;
  private criteres_sociaux!: boolean;
  private piece_la!: string | null;
  private piece_rc!: string | null;
  private piece_dc!: string | null;
  private piece_pc!: string | null;
  private lien_aapc!: string | null;
  private lien_rc!: string | null;
  private lien_dce!: string | null;
  private is_piece_dc!: boolean;
  protected is_dume!: boolean;
  protected id_dume!: string | null;
  private restreinte!: boolean;
  private etape_consultation!: EtapeConsultation;
  private date_activation_JOUE!: number | null;
  private identifiant_passation!: string;
  protected reponse_electronique!: boolean;
  private en_ligne!: boolean;
  private lien_depot!: string | null;

  constructor(consultation: any) {
    super(consultation);
    this.cle_etab = consultation.cle_etab;
    this.cle_dce = consultation.cle_dce;
    this.nb_lots = consultation.nb_lots;
    this.lien_depot = consultation.lien_depot;
    this.identifiant_passation = consultation.identifiant_passation;
    this.objet_affaire = consultation.objet_affaire;
    this.denomination_pa = consultation.denomination_pa;
    this.date_cloture_pa_f = consultation.date_cloture_pa_f;
    this.date_cloture_pa_f_s = consultation.date_cloture_pa_f_s;
    this.date_cloture_f = consultation.date_cloture_f;
    this.date_cloture_f_s = consultation.date_cloture_f_s;
    this.date_publication = consultation.date_publication;
    this.etape_consultation = consultation.etape_consultation;
    this.criteres_sociaux = !_.isNil(consultation.criteres_sociaux) ? (consultation.criteres_sociaux == 1) ? true : false : false;
    this.date_activation_JOUE = !_.isNil(consultation.date_activation_JOUE) ? consultation.date_activation_JOUE : null;
    this.criteres_environnementaux = !_.isNil(consultation.criteres_environnementaux) ? (consultation.criteres_environnementaux == 1) ? true : false : false;
    this.is_piece_dc = !_.isNil(consultation.is_piece_dc) ? (consultation.is_piece_dc == 1) ? true : false : false;
    this.restreinte = !_.isNil(consultation.restreinte) ? (consultation.restreinte == 1) ? true : false : false;
    this.reponse_electronique = !_.isNil(consultation.reponse_electronique) ? (consultation.reponse_electronique == 1) ? true : false : false;
    this.is_dume = !_.isNil(consultation.consultation) ? (consultation.is_dume == 1) ? true : false : false;
    this.en_ligne = !_.isNil(consultation.en_ligne) ? (consultation.en_ligne == 1) ? true : false : false;
    this.piece_la = !_.isNil(consultation.piece_la) ? !_.isEmpty(consultation.piece_la) ? consultation.piece_la : null : null;
    this.piece_rc = !_.isNil(consultation.piece_rc) ? !_.isEmpty(consultation.piece_rc) ? consultation.piece_rc : null : null;
    this.piece_pc = !_.isNil(consultation.piece_pc) ? !_.isEmpty(consultation.piece_pc) ? consultation.piece_pc : null : null;
    this.piece_dc = !_.isNil(consultation.piece_dc) ? !_.isEmpty(consultation.piece_dc) ? consultation.piece_dc : null : null;
    this.lien_aapc = !_.isNil(consultation.lien_aapc) ? !_.isEmpty(consultation.lien_aapc) ? consultation.lien_aapc : null : null;
    this.lien_dce = !_.isNil(consultation.lien_dce) ? !_.isEmpty(consultation.lien_dce) ? consultation.lien_dce : null : null;
    this.lien_rc = !_.isNil(consultation.lien_rc) ? !_.isEmpty(consultation.lien_rc) ? consultation.lien_rc : null : null;
    this.id_dume = !_.isNil(consultation.id_dume) ? !_.isEmpty(consultation.id_dume) ? consultation.id_dume : null : null;
  }

  get accessBase(): AccessConsultation {
    return {
      code: null,
      isRestreinte: this.restreinte
    }
  }

  get response(): ResponseConsultation {
    return {
      electronique: this.reponse_electronique
    }
  }

  get dume(): Dume {
    return {
      hasDume: this.is_dume && !_.isNull(this.id_dume),
      id: this.id_dume
    }
  }

  get dateActivationJoue(): number | null {
    return this.date_activation_JOUE
  }

  get idPassation(): string {
    return this.identifiant_passation;
  }

  get depotLink(): string | null {
    return this.lien_depot;
  }

  get cleDce(): string {
    return this.cle_dce
  }

  get cleEtab(): string {
    return this.cle_etab
  }

  get etape(): EtapeConsultation {
    return this.etape_consultation;
  }

  get objetAffaire(): string {
    return this.objet_affaire;
  }

  get nbLots(): number {
    return this.nb_lots;
  }

  get online(): boolean {
    return this.en_ligne;
  }

  get denominationPa(): string | null {
    return _.isNil(this.denomination_pa) ? null : _.isEmpty(this.denomination_pa) ? null : this.denomination_pa;
  }

  get dateCloturePa(): DateCloturePa {
    return {
      timeStamp: this.date_cloture_pa,
      dateFormated: this.date_cloture_pa_f,
      dateFormatedWithSecond: this.date_cloture_pa_f_s
    };
  }

  get dateCloture(): DateCloture {
    return {
      ...this.dateClotureDefault,
      dateFormated: this.date_cloture_f,
      dateFormatedWithSecond: this.date_cloture_f_s
    };
  }

  get datePublication(): DatePublication {
    return {
      ...this.datePublicationDefault,
      timeStamp: this.date_publication,
    };
  }

  get dateClotureUser(): DateClotureUser {
    return {
      timeStamp: this.date_cloture_user,
      dateFormated: this.date_cloture_user_f,
      dateFormatedWithSecond: this.date_cloture_user_f_s
    }
  }

  get criteres(): CriteresConsultation {
    return {
      sociaux: this.criteres_sociaux,
      environementaux: this.criteres_environnementaux,
    }
  }

  get pieces(): PiecesConsultation {
    return {
      la: this.piece_la,
      pc: this.piece_pc,
      rc: this.piece_rc,
      dc: this.piece_dc,
      link: {
        aapc: this.lien_aapc,
        dce: this.lien_dce,
        rc: this.lien_rc
      },
      isPieceDc: this.is_piece_dc
    }
  }

}


export interface AccessConsultation {
  isRestreinte: boolean;
  code: string | null;
}

export interface ResponseConsultation {
  electronique?: boolean;
}

export interface CriteresConsultation {
  sociaux: boolean;
  environementaux: boolean;
}

export interface Dume {
  id: string | null;
  hasDume: boolean;
}

export interface DateCloture extends DateClotureDefault {
  dateFormated: string;
  dateFormatedWithSecond: string;
}

export interface DatePublication extends DatePublicationDefault {
  timeStamp: number;
}

export interface DateClotureUser {
  timeStamp?: number;
  dateFormated?: string;
  dateFormatedWithSecond?: string;
}

export interface DateCloturePa {
  timeStamp?: number;
  dateFormated: string;
  dateFormatedWithSecond: string;
}

export interface PiecesConsultation {
  la: string | null;
  pc: string | null;
  rc: string | null;
  dc: string | null;
  link: PieceLinks;
  isPieceDc: boolean;
}

export interface PieceLinks {
  aapc: string | null;
  rc: string | null;
  dce: string | null;
}
