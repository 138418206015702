import { AncChorusComponent } from '../../components/anc-chorus/anc-chorus.component';
import { AncDepartmentPrestationComponent } from '../../components/anc-department-prestation/anc-department-prestation.component';
import { AncFacturationAdressComponent } from '../../components/anc-facturation-adress/anc-facturation-adress.component';
import { AncInfoComponent } from '../../components/anc-info/anc-info.component';
import { AncOptionEnvoiComponent } from '../../components/anc-option-envoi/anc-option-envoi.component';
// import { AncPublicationSupportComponent } from '../../components/anc-publication-support/anc-publication-support.component';
// import { AncSupportFacultatifMessageComponent } from '../../components/anc-support-facultatif-message/anc-support-facultatif-message.component';
import { FormDisplay } from '../../../../../../core/models/pa/annonce/form-display.model';
import { AncTypePaComponent } from '@atline/marches-securises/pa/edition/edition-annonce/components/anc-type-pa/anc-type-pa.component';
import { AncOrganisationEformComponent } from '../../components/anc-organisation-eform/anc-organisation-eform.component';
import { AncLotsComponent } from '../../components/anc-lots/anc-lots.component';
import { AncPrincipalActivityComponent } from '../../components/anc-principal-activity/anc-principal-activity.component';
import { AncLegalBasisProcedureComponent } from '../../components/anc-legal-basis-procedure/anc-legal-basis-procedure.component';
import { AncAutreBaseJuridiqueComponent } from '../../components/anc-autre-base-juridique/anc-autre-base-juridiquecomponent';
import { AncBaseSansIdentifiantComponent } from '../../components/anc-base-sans-identifiant/anc-base-sans-identifiant.component';
import { AncTvaMarcheComponent } from '../../components/anc-tva-marche/anc-tva-marche.component';
import { AncAdditionalInfosExecussionPlaceComponent } from '../../components/anc-additional-infos-execussion-place/anc-additional-infos-execussion-place.component';
import { AncConditionsContratsComponent } from '../../components/anc-conditions-contrats/anc-conditions-contrats.component';
import { AncDroitsTransfComponent } from '../../components/anc-droits-transf/anc-droits-transf.component';
import { AncProcedureComponent } from '../../components/anc-procedure/anc-procedure.component';
// import { AncProcedureAccelereeComponent } from '../../components/anc-procedure-acceleree/anc-procedure-acceleree.component';
import { AncInformationsComplComponent } from '../../components/anc-informations-compl/anc-informations-compl.component';
import { AncReferencePubComponent } from '../../components/anc-reference-pub/anc-reference-pub.component';
import { AncObjetMarcheComponent } from '../../components/anc-objet-marche/anc-objet-marche.component';
import { AncDataContactComponent } from '../../components/anc-data-contact/anc-data-contact.component';
import { AncCpvClassificationComponent } from '../../components/anc-cpv-classification/anc-cpv-classification.component';
import { AncInfoExcluAcheteurComponent } from '../../components/anc-info-exclu-acheteur/anc-info-exclu-acheteur.component';
import { AncConditionsLanguageComponent } from '../../components/anc-conditions-language/anc-conditions-language.component';
import { AncDescriptionProcedureComponent } from '../../components/anc-description-procedure/anc-description-procedure.component';


const eform_17: FormDisplay = {
  0: {
    id: 'pub',
    text: 'APP.ANNONCES.DISPLAYS.EFORM_17.0.TEXT',
    title: 'APP.ANNONCES.DISPLAYS.EFORM_17.0.TITLE',
    blocs: {
      bloc1: {
        title: 'APP.ANNONCES.DISPLAYS.EFORM_17.0.BLOCKS.1.TITLE',
        expansion: true,
        comps: [
          {
            component: AncOptionEnvoiComponent,
            required: {
              compteBoamp: true,
              destinataire: true,
              langue: true,
            },
            composition: {
              destinataire: true,
              compteBoamp: true,
              langue: true
            }
          }
        ]
      },
      bloc2: {
        title: 'APP.ANNONCES.DISPLAYS.EFORM_17.0.BLOCKS.2.TITLE',
        expansion: true,
        comps: [
          {
            component: AncDepartmentPrestationComponent,
            required: {
              defaultDepartement: true,
              otherDepartment: false,
              info: true
            },
            composition: {
              defaultDepartement: true,
              otherDepartment: true,
              info: true
            }
          }
        ]
      },
      // bloc3: {
      //   title: 'APP.ANNONCES.DISPLAYS.EFORM_17.0.BLOCKS.3.TITLE',
      //   expansion: true,
      //   comps: [
      //     {
      //       component: AncSupportFacultatifMessageComponent,
      //       required: {
      //         message: false
      //       },
      //       composition: {
      //         message: true
      //       }
      //     },
      //     {
      //       component: AncPublicationSupportComponent,
      //       required: {
      //         list: false
      //       },
      //       composition: {
      //         list: true
      //       }
      //     }
      //   ]
      // }
    }
  },
  1: {
    title: 'APP.ANNONCES.DISPLAYS.EFORM_17.1.TITLE',
    text: "APP.ANNONCES.DISPLAYS.EFORM_17.1.TITLE",
    hideSave: true,
    blocs: {
      bloc1: {
        title: 'APP.ANNONCES.DISPLAYS.EFORM_17.1.BLOCKS.1.TITLE',
        comps: [
          {
            component: AncOrganisationEformComponent,
            required: {
              name: true,
              department: false,
              siret: true,
              country: true,
              ville: true,
              mel: true
            },
            composition: {
              name: true,
              department: true,
              siret: true,
              contact: true,
              address1: true,
              address2: true,
              address3: true,
              cp: true,
              ville: true,
              country: true,
              phone: true,
              fax: true,
              mel: true,
              url: true,
              exchangeUrl: true,
              isPhysicalPeople: true,
              sizeOe: false,
              laureat: true,
              chefDeFil: true,
              central1: true,
              central2: true
            }
          }
        ],
      }
    }
  },
  2: {
    title: 'APP.ANNONCES.DISPLAYS.EFORM_17.2.TEXT',
    text: "APP.ANNONCES.DISPLAYS.EFORM_17.2.TEXT",
    blocs: {
      bloc1: {
        title: 'APP.ANNONCES.DISPLAYS.EFORM_17.2.BLOCKS.1.TITLE',
        comps: [
          {
            component: AncDataContactComponent,
            required: {
              typePA: true,
              autreOrguanisme: true
            },
            composition: {
              nameOrganisation: true,
              contactPoint: true,
              departement: true,
              street: {
                name: true,
                additional: true,
                line: true
              },
              zipcode: true,
              country: true,
              codeNUTS: true,
              siret: true,
              phone: true,
              fax: true,
              mail: true,
              webSite: true,
              webSitePA: true,
            }
          }
        ]
      },
      bloc2: {
        title: 'APP.ANNONCES.DISPLAYS.EFORM_17.2.BLOCKS.2.TITLE',
        comps: [
          {
            component: AncInfoExcluAcheteurComponent,
            composition: {
              isGroupLeader: true,
              isCentralPurchasing: true,
              isCentralPurchasingBodySuppliers: true
            }
          }
        ],
      },
      bloc3: {
        title: 'APP.ANNONCES.DISPLAYS.EFORM_17.2.BLOCKS.3.TITLE',
        comps: [
          {
            component: AncTypePaComponent,
            required: {
              typePA: true,
              autreOrguanisme: true
            },
            composition: {
              typePA: true,
              autreOrguanisme: true
            }
          },
          {
            component: AncPrincipalActivityComponent,
            required: {
              activities: true
            },
            composition: {
              activities: true
            }
          }
        ]
      }
    }
  },
  3: {
    title: 'APP.ANNONCES.DISPLAYS.EFORM_17.3.TEXT',
    text: "APP.ANNONCES.DISPLAYS.EFORM_17.3.TEXT",
    tabs: {
      tab1: {
        title: 'Objet',
        blocs: {
          bloc1: {
            title: 'Référence de l\'avis précédent (GR-Previous-Notice)',
            comps: [
              {
                component: AncReferencePubComponent,
                composition: { previousTender: true },
                required: { previousTender: false },
              }
            ]
          },
          bloc2: {
            title: 'Base juridique (GR-Procedure-MainLegalBasis)',
            comps: [
              {
                component: AncLegalBasisProcedureComponent,
                composition: { legalBasisProcedure: true },
                required: { legalBasisProcedure: true },
              }
            ]
          },
          bloc3: {
            title: 'Autre base juridique avec un identifiant (GR-Procedure-LocalLegalBasisWithID)',
            comps: [
              {
                component: AncAutreBaseJuridiqueComponent,
                composition: { infoData: true, selectBase: true },
                required: { infoData: false, selectBase: false },
              }
            ]
          },
          bloc4: {
            title: 'Autre base juridique sans identifiant connu (GR-Procedure-LocalLegalBasisNoID)',
            comps: [
              {
                component: AncBaseSansIdentifiantComponent,
                composition: { infoData: true },
                required: { infoData: false },
              },
            ]
          },
          bloc5: {
            title: 'Conditions de passation du marché (GR-Procedure-Description)',
            comps: [
              {
                component: AncDescriptionProcedureComponent,
                composition: { identifiantInterne: true, titre: true, natureContract: true, natureAdditionalContract: false, infoData: false },
                required: { identifiantInterne: false, titre: true, natureContract: true, infoData: false },
              },
              {
                component: AncObjetMarcheComponent,
                composition: { intitule: true },
                required: { intitule: false },
              },
            ]
          },
          bloc6: {
            title: 'Étendue du marché (GR-Procedure-Scope)',
            comps: [
              {
                component: AncTvaMarcheComponent,
                composition: { value: true, currency: true, valuemax: true },
                required: { value: false, currency: false, valuemax: false },
              },
              {
                component: AncCpvClassificationComponent,
                composition: { principal: true, cpv: true, cpv1: true, cpv2: true, cpv3: true },
                required: { principal: true, cpv: true, cpv1: true, cpv2: true, cpv3: true }
              },
              {
                component: AncConditionsLanguageComponent,
                composition: {languages: true},
                required: {languages: true}
              }
            ]
          },
          bloc7: {
            title: 'A location where the Procurement, for the Procedure as a whole, should take place (GR-Procedure-PlaceOfPerformance)',
            comps: [
              {
                component: AncAdditionalInfosExecussionPlaceComponent,
                composition: { adr1: false, adr2: false, adr3: false, country: true, nuts: false, cp: false, city: false, restrict: true, infos_comp_marche: false, infos_comp_procedure: false },
                required: { adr1: false, adr2: false, adr3: false, country: false, nuts: false, cp: false, city: false, restrict: false, infos_comp_marche: false, infos_comp_procedure: false },
              },
            ]
          }
        }
      },
      tab2: {
        title: 'Conditions de passation du marché',
        blocs: {
          bloc1: {
            title: 'Motifs d’exclusion (GR-Procedure-ExclusionGrounds)',
            comps: [
              {
                component: AncConditionsContratsComponent,
                composition: {
                  codeNational: true,
                  codeFaillite: true,
                  codeCorruption: true,
                  codeCreanciers: true,
                  codeOrg: true,
                  codeDistorsion: true,
                  codeEnv: true,
                  codeBlanchiment: true,
                  codeFraude: true,
                  codeTraf: true,
                  codeInsolv: true,
                  codeTravail: true,
                  codeLiquid: true,
                  codeFausse: true,
                  codeNationaux: true,
                  codeConflits: true,
                  codePrep: true,
                  codeFaute: true,
                  codeSanction: true,
                  codeOblig: true,
                  codePay: true,
                  codeActiv: true,
                  codeImpots: true,
                  codeInter: true,
                },
                required: {
                  codeNational: true,
                  codeFaillite: true,
                  codeCorruption: true,
                  codeCreanciers: true,
                  codeOrg: true,
                  codeDistorsion: true,
                  codeEnv: true,
                  codeBlanchiment: true,
                  codeFraude: true,
                  codeTraf: true,
                  codeInsolv: true,
                  codeTravail: true,
                  codeLiquid: true,
                  codeFausse: true,
                  codeNationaux: true,
                  codeConflits: true,
                  codePrep: true,
                  codeFaute: true,
                  codeSanction: true,
                  codeOblig: true,
                  codePay: true,
                  codeActiv: true,
                  codeImpots: true,
                  codeInter: true,
                }
              }
            ]
          },
          bloc2: {
            title: 'Droit transfrontière (GR-Procedure-CrossBorderLaw)',
            comps: [
              {
                component: AncDroitsTransfComponent,
                composition: {

                }
              }
            ]
          }
        }
      },
      tab3: {
        title: 'Détails du type de procédure',
        blocs: {
          bloc1: {
            title: 'Objet (GR-Procedure-Procedure)',
            comps: [
              {
                component: AncProcedureComponent,
                composition: {
                  description: true,
                  procedure: true,
                  relanceInfructeux: true,
                },
                required: {
                  description: false,
                  procedure: true,
                  relanceInfructeux: false,
                }
              }
            ]
          },
          bloc2: {
            title: 'Informations complémentaires (GR-Procedure-AdditionalInformation)',
            comps: [
              {
                component: AncInformationsComplComponent,
                composition: {
                  description: true,
                },
                required: {
                  description: false,
                }
              }
            ]
          }
        }
      },
      // tab4: {
      //   title: 'Distribution des lots',
      //   blocs: {
      //     bloc1: {
      //       title: 'Nombre maximal de lots (GR-Procedure-MaxLots)',
      //       comps: [
      //         {
      //           component: AncDivisionLotsComponent,
      //           required: {
      //             value: true,
      //             valuemax: false,
      //             repartitionLots: true,
      //           },
      //           composition: {
      //             value: true,
      //             valuemax: true,
      //             repartitionLots: true,
      //           }
      //         },
      //       ]
      //     }
      //   }
      // }
    },
  },
  4: {
    title: 'APP.ANNONCES.DISPLAYS.EFORM_17.4.TEXT',
    text: "APP.ANNONCES.DISPLAYS.EFORM_17.4.TEXT",
    hideSave: false,
    blocs: {
      blocs1: {
        title: 'Mes Lots',
        comps: [
          {
            component: AncLotsComponent,
            composition: {
              critereSelection: true,
              elecauction:true,
              programFC:true,
              identifiantFC: true,
              infosFC: true,
              nbrCandidatFC: true,
              justifFC: true,
              stage_elimFC: true,
              displayAwardingConsequences: true,
              juridiqueGroupFC:true,
              participationResvFC:true,
              variantesFC:true,
              recrrentApprovFC:true,
              execmarchFC:true,
              facturationFC: true,
              nomqualificationsFC: true,
              securiteFC: true,
              accordDivulgationFC: true,
              catalogElecFC: true,
              cachetElecFC: true,
              cammandeElecFC: true,
              arrangementFC: true,
              destinataireOffreFC: true,
              evalueateurFC: true,
              information : true,
              dateRecepFC: true,
              typeValidateFC: true,
              dateOuvertureFC: true,
              garantieFC: true,
              soumissionElecFC: true,
              nomSoumissFC: false,principal: false, cpv: true, cpv1: true, cpv2: true, cpv3: true 
            },
            required: {
              elecauctionFC:true,
              programFC:true,
              identifiantFC: true,
              infosFC: true,
              nbrCandidatFC: true,
              justifFC: true,
              stage_elimFC: true,
              displayAwardingConsequences: true,
              juridiqueGroupFC:true,
              participationResvFC:true,
              variantesFC:true,
              recrrentApprovFC:true,
              execmarchFC:true,
              facturationFC: true,
              nomqualificationsFC: true,
              securiteFC: true,
              accordDivulgationFC: true,
              catalogElecFC: true,
              cachetElecFC: true,
              cammandeElecFC: true,
              arrangementFC: true,
              destinataireOffreFC: false,
              evalueateurFC: false,
              dateRecepFC: false,
              typeValidateFC: false,
              dateOuvertureFC: false,
              garantieFC: false,
              soumissionElecFC: false,
              nomSoumissFC: false, principal: false, cpv: true, cpv1: true, cpv2: true, cpv3: true 
            }

          }
        ]
      }
    }
  },
  5: {
    id: 'fac',
    title: 'APP.ANNONCES.DISPLAYS.EFORM_17.9.TITLE',
    text: '€',
    blocs: {
      bloc1: {
        title: 'APP.ANNONCES.DISPLAYS.EFORM_17.5.BLOCKS.1.TITLE',
        comps: [
          {
            component: AncInfoComponent,
            translateKey: 'FACTURATION',
            etat: 'warning',
            required: {},
            composition: {}
          },
          {
            component: AncChorusComponent,
            required: {
              siret: false,
              tvaIntra: false,
              engagement: false
            },
            composition: {
              siret: true,
              tvaIntra: true,
              engagement: true
            }
          }
        ]
      },
      bloc2: {
        title: 'APP.ANNONCES.DISPLAYS.EFORM_17.5.BLOCKS.2.TITLE',
        comps: [{
          component: AncFacturationAdressComponent,
          required: {
            profil: false,
            name: false,
            recipant: true,
            building: true,
            road: true,
            special: true,
            cp: true,
            city: true

          },
          composition: {
            profil: false,
            name: true,
            recipant: true,
            building: true,
            road: true,
            special: true,
            cp: true,
            city: true
          }
        }]
      }
    }
  }
};

export default eform_17;
