// TODO change API url in prod to the correct one
export const environment = {
  env: 'prd',
  production: true,
  version: '3.0.228.199',
  lang: 'FR',
  hotline: '+33488050770',
  api: 'https://wsa.atline.fr/',
  redirectUrl : 'https://dev.atline-services.com/',
  clientId : 'atline-services',
  clientSecret : '22360C1B138EA4EA935F1B28FB1B16CB',
  grantTypeClientCredentials: 'client_credentials',
  msFrLinkRedirection: 'https://www.marches-securises.fr/entreprise/?module=sso|connecter_utilisateur_s&access_token=',
  originPlateforme: '2',
  features: {},
  mediaURL: '',
  atlineFormation: 'https://www.atline-formations.fr/',
  mailCse: 'cse@atline.fr',
  activation: {
    pa: false
  }
};
