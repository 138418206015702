import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RoutingEnum } from '@atline-shared/enums/routing.enum';
import { BooleanValue } from '@atline/core/types/boleanMs.type';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

export class ConfirmationModal {
  consId = '';
  publicationDate = '';
  closureDate = '';
  object = '';
  refInterne = '';
  prodedure = '';
  reciever = '';
  nbDceRetrieved = '';
  paName = '';
  alreadyPublished!: BooleanValue;
  retraits = {
    electronic: 0,
    papier: 0
  };
}

@Component({
  selector: 'app-confirme-publication',
  templateUrl: './confirme-publication.component.html',
  styleUrls: ['./confirme-publication.component.scss']

})
export class ConfirmePublicationComponent implements OnInit {

  private SUBJECT = '';

  public form!: FormGroup;
  public informOe = new FormControl(0);
  public subject = new FormControl('', [Validators.required]);
  public emailContent = new FormControl('', [Validators.required]);
  public shareOnQR = new FormControl(0);

  constructor(
    readonly dialogRef: MatDialogRef<ConfirmePublicationComponent>,
    private readonly router: Router,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationModal,
    private readonly formBuilder: FormBuilder,
    private readonly translateService: TranslateService
  ) { }

  ngOnInit(): void {
    console.log(this.data);
    
    this.form = this.formBuilder.group({
      informOe: this.informOe
    });

    this.informOe.valueChanges.subscribe(res => {
      if (res) this.addFormControlToFormGroup();
      else this.removeFormControlToFormGroup();
    });

    this.translateService.get('APP.MARCHES_SECURISES.PA.CREATE.CONFIRMATION_POPUP.MAIL_SUBJECT', {
      consId: this.data.consId,
    }).subscribe((res) => {
      this.SUBJECT = res;
    });

    this.getDefaultEmailContent();

  }

  get datePublicationWithoutHours(): string {
    console.log(this.data);
    
    return this.data.publicationDate.split('-')[0];
  }

  private getDefaultEmailContent(): void {
    this.translateService.get('APP.MARCHES_SECURISES.PA.CREATE.CONFIRMATION_POPUP.MAIL_CONTENT', {
      consId: this.data.consId,
      // tslint:disable-next-line:max-line-length
      refInterne: (!_.isEmpty(this.data.refInterne) && !_.isUndefined(this.data.refInterne)) ? 'Référence interne : ' + this.data.refInterne : '',
      consObject: this.data.object,
      paName: !_.isEmpty(this.data.paName) ? this.data.paName : ''
    }).subscribe((res) => {
      this.emailContent.setValue(res);
    });
  }

  get nbRetrait(): number {
    return this.data.retraits.electronic + this.data.retraits.papier;
  }


  /**
   * close dc dialaog
   */
  public close(): void {
    this.dialogRef.close({
      state: false
    });
  }

  public confirm(): void {
    this.dialogRef.close({
      state: true,
      sendEmails: this.informOe.value,
      text: this.emailContent.value,
      subject: this.subject.value,
      shareOnQR: this.shareOnQR.value
    });
  }

  /**
   * add form control of email form
   */
  private addFormControlToFormGroup(): void {
    this.form.addControl('subject', this.subject);
    this.form.addControl('emailContent', this.emailContent);
    this.form.addControl('shareOnQR', this.shareOnQR);
    this.form.updateValueAndValidity();
    this.subject.setValue(this.SUBJECT);
    this.getDefaultEmailContent();
    // this.emailContent.setValue(this.defaultEmailContent);
  }


  /**
   * remove form control of email form
   */
  private removeFormControlToFormGroup(): void {
    this.form.removeControl('subject');
    this.form.removeControl('emailContent');
    this.form.removeControl('shareOnQR');
    this.form.updateValueAndValidity();
  }

  public goToList(): void {
    this.router.navigate([RoutingEnum.MARCHES_SECURISES, RoutingEnum.PA, RoutingEnum.CONSULTATION]);
    this.close();
  }


}
