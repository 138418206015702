import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { ApiService } from './api.service';
import { EditAnnonceRequest } from '../models/api/requests/annonce/editAnnonce.request';
import { EditAnnonceResponse } from '../models/api/responses/annonce/editAnnonce.response';
import { Observable } from 'rxjs';
import { AccountsRequest } from '../models/api/requests/annonce/accounts.request';
import { AccountsResponse } from '../models/api/responses/annonce/accounts.response';
import { DeleteAnnonceRequest } from '../models/api/requests/annonce/delete.request';
import { DeleteAnnonceResponse } from '../models/api/responses/annonce/delete.response';
import { RequestForPa } from '../models/api/requests/requestForPa.request';
import { FormulairesAnnonceResponse } from '../models/api/responses/formualiresList.response';
import { AnnonceListRequest } from '../models/api/requests/annonce/annonceList.request';
import { AnnonceListResponse } from '../models/api/responses/annonce/annonceList.response';
import { VerifyRulesRequest } from '../models/api/requests/annonce/verifyRules.request';
import { VerifyRulesResponse } from '../models/api/responses/annonce/verifyRules.response';
import { RequestForPaWithAnnonce } from '../models/api/requests/annonce/requestForPaAnnonce.request';
import { LotAnnonce, LotsListAnnonceResponse } from '../models/api/responses/annonce/lotsList.response';
import { EditLotRequest } from '../models/api/requests/annonce/editLot.request';
import { CurrenciesListRequest } from '../models/api/requests/currenciesList.request';
import { CurrenciesListResponse } from '../models/api/responses/currenciesList.response';
import { AnnonceDetailsRequest } from '../models/api/requests/annonce/annonceDetails.request';
import { AnnonceDetails } from '../models/api/responses/annonce/annonceDetails.response';
import { DeleteLotRequest } from '../models/api/requests/annonce/deleteLot.request';

import { MessageResponse } from '../models/api/responses/message.response';
import { SupportsListRequest } from '../models/api/requests/annonce/supportsList.request';
import { SupportListResponse } from '../models/api/responses/supportsList.response';
import { environment } from 'src/environments/environment';
import { AnnonceXMLRequest } from '../models/api/requests/annonce/annonceXML.request';
import { UtilsService } from './utils.service';
import { AttributionsListRequest } from '../models/api/requests/annonce/attributionsList.request';
import { AttributionAnnonce, AttributionsListAnnonceResponse } from '../models/api/responses/annonce/attributionsList.response';
import { SoumissionnaireAnnonce, SoumissionnairesListAnnonceResponse } from '../models/api/responses/annonce/soumissionnairesList.response';
import { SoumissionnairesListRequest } from '../models/api/requests/annonce/soumissionnairesList.request';
import { DeleteSoumissionnaireRequest } from '../models/api/requests/annonce/deleteSoumissionnaire.request';
import { EditSoumissionnaireRequest } from '../models/api/requests/annonce/editSoumissionnaire.request';
import { DeleteOffreRequest } from '../models/api/requests/annonce/deleteOffre.request';
import { OffresListAnnonceResponse } from '../models/api/responses/annonce/offresList.response';
import { OffresListRequest } from '../models/api/requests/annonce/offresList.request';
import { EditOffreRequest } from '../models/api/requests/annonce/editOffre.request';
import { DeleteMarcheRequest } from '../models/api/requests/annonce/deleteMarche.request';
import { MarchesListRequest } from '../models/api/requests/annonce/marchesList.request';
import { MarcheAnnonce, MarchesListAnnonceResponse } from '../models/api/responses/annonce/marchesList.response';
import { EditMarcheRequest } from '../models/api/requests/annonce/editMarche.request';
import { EditAttributionRequest } from '../models/api/requests/annonce/editAttribution.request';
import { DeleteAttributionRequest } from '../models/api/requests/annonce/deleteAttribution.request';
import { EditAnnonceOrgansationResquest, GetOrganisationRequest, RemoveOrganisationRequest } from '../models/api/requests/annonce/organisation.request';
import { OrganiastionListAnnonceResponse } from '../models/api/responses/annonce/organisationList.response';
import { CritereSelectionRequest } from '../models/api/requests/annonce/critereSelection.request';
import { CriteresSelectionListAnnonceResponse } from '../models/api/responses/annonce/criteresList.response';
import { DeleteCritereSelectRequest } from '../models/api/requests/deleteCritereSelection.request';
import { CritereSelectionListRequest } from '../models/api/requests/annonce/critereSelectionList.request';
import { CritereAttribRequest } from '../models/api/requests/annonce/critetreAttribution.request';
import { CritereAttributionListAnnonceResponse } from '../models/api/responses/annonce/critereAttributionList.response';
import { DeleteCritereAttributionRequest } from '../models/api/requests/deleteCritereAttrbution.request';
import { OffreAnnonceItem } from '../models/OffreAnnonceItem.model';
import { EditAnnonceRectificationResquest, GetRectificatioRequest, RemoveRectificationRequest } from '../models/api/requests/annonce/rectifications.request';
import { RectificationResponse } from '../models/api/responses/rectification.response';
import { DeletePartieRequest, EditPartieRequest, GetPartieRequest } from '../models/api/requests/annonce/partie.request';
import { PartResponse } from '../models/api/responses/annonce/part.response';
import { PartItem } from '../models/PartItem.model';
import { DefaultResponse } from '../models/api/responses/default.response';

@Injectable({
  providedIn: 'root'
})
export class AnnoncesService {

  constructor(
    private readonly api: ApiService,
    private readonly http: HttpClient,
    private readonly utils: UtilsService,
  ) { }

  getAccounts(request: AccountsRequest): Observable<AccountsResponse> {
    return this.api.post(AccountsResponse, request, `seal/account_list`);
  }

  editAnnonce(request: EditAnnonceRequest): Observable<EditAnnonceResponse> {    
    return this.api.post(EditAnnonceResponse, request, 'seal/annonce_edit');
  }

  delete(request: DeleteAnnonceRequest): Observable<DeleteAnnonceResponse> {
    return this.api.delete(DeleteAnnonceResponse, request, 'seal/delete_annonce');
  }

  getFormulaires(request: RequestForPa): Observable<FormulairesAnnonceResponse> {
    return this.api.post(FormulairesAnnonceResponse, request, 'seal/formulaires_list');
  }

  annonces_list(request: AnnonceListRequest): Observable<AnnonceListResponse> {
    return this.api.post(AnnonceListResponse, request, 'seal/annonce_list');
  }

  verifyRules(request: VerifyRulesRequest): Observable<VerifyRulesResponse> {
    return this.api.post(VerifyRulesResponse, request, 'verify_rules');
  }

  lots_list(request: RequestForPaWithAnnonce): Observable<LotsListAnnonceResponse> {
    return this.api.post(LotsListAnnonceResponse, request, 'seal/lots_list');
  }

  soumissionnaires_list(request: SoumissionnairesListRequest): Observable<SoumissionnairesListAnnonceResponse> {
    return this.api.post(SoumissionnairesListAnnonceResponse, request, 'seal/soumissionnaire_list');
  }

  offres_list(request: OffresListRequest): Observable<OffresListAnnonceResponse> {
    return this.api.post(OffresListAnnonceResponse, request, 'seal/offre_list');
  }

  marches_list(request: MarchesListRequest): Observable<MarchesListAnnonceResponse> {
    return this.api.post(MarchesListAnnonceResponse, request, 'seal/marches_list');
  }

  attributions_list(request: AttributionsListRequest): Observable<AttributionsListAnnonceResponse> {
    return this.api.post(AttributionsListAnnonceResponse, request, 'seal/attributions_list');
  }

  editLot(request: EditLotRequest): Observable<LotAnnonce> {
    return this.api.post(LotAnnonce, request, 'seal/lots_edit');
  }

  editSoumissionnaire(request: EditSoumissionnaireRequest): Observable<SoumissionnaireAnnonce> {
    return this.api.post(SoumissionnaireAnnonce, request, 'seal/soumissionnaire_add');
  }

  editMarche(request: EditMarcheRequest): Observable<MarcheAnnonce> {
    return this.api.post(MarcheAnnonce, request, 'seal/marche_edit');
  }

  editOffre(request: EditOffreRequest): Observable<OffreAnnonceItem> {
    return this.api.post(OffreAnnonceItem, request, 'seal/offre_edit');
  }

  editAttribution(request: EditAttributionRequest): Observable<AttributionAnnonce> {
    return this.api.post(AttributionAnnonce, request, 'seal/attribution_edit');
  }

  deleteLot(request: DeleteLotRequest): Observable<MessageResponse> {
    return this.api.delete(MessageResponse, request, 'seal/delete_annonce_lot');
  }

  deleteSoumissionnaire(request: DeleteSoumissionnaireRequest): Observable<any> {
    return this.api.post(MessageResponse, request, 'seal/soumissionnaire_remove');
  }

  deleteOffre(request: DeleteOffreRequest): Observable<any> {
    return this.api.post(MessageResponse, request, 'seal/offre_remove');
  }

  deleteMarche(request: DeleteMarcheRequest): Observable<any> {
    return this.api.post(MessageResponse, request, 'seal/marche_remove');
  }

  deleteAttribution(request: DeleteAttributionRequest): Observable<any> {
    return this.api.delete(MessageResponse, request, 'seal/delete_attribution');
  }

  getCurrencies(request?: CurrenciesListRequest): Observable<CurrenciesListResponse> {
    return this.api.post(CurrenciesListResponse, request, 'seal/currencies_list');
  }

  getAnnonceDetails(request: AnnonceDetailsRequest): Observable<AnnonceDetails> {
    return this.api.post(AnnonceDetails, request, 'seal/details_annonce');
  }

  supportsList(request: SupportsListRequest): Observable<SupportListResponse> {
    return this.api.post(SupportListResponse, request, 'seal/supports_list');
  }

  annonceXML(params: AnnonceXMLRequest & { progress?: false }): Observable<string>;
  annonceXML(params: AnnonceXMLRequest & { progress?: true }): Observable<HttpEvent<string>>;
  annonceXML(params: AnnonceXMLRequest & { progress?: boolean }): Observable<HttpEvent<string> | string> {
    const progress = params.progress ?? false;
    const request = { ...params, progress: undefined };
    const token = this.utils.token;

    return this.http.post(`${environment.api}seal/annonce_export_xml`, request, {
      observe: (progress ? 'events' : 'body') as any,
      responseType: 'text',
      reportProgress: progress,
      headers: new HttpHeaders({ Authorization: `Bearer ${token}` }),
    });
  }

  getOrganisations(req: GetOrganisationRequest): Observable<any> {
    return this.api.post(OrganiastionListAnnonceResponse, req, 'seal/organisation_list');
  }

  createUpdateOrganisation(request: EditAnnonceOrgansationResquest): Observable<any> {    
    return this.api.post(OrganiastionListAnnonceResponse, request, 'seal/organisation_edit');
  }

  deleteOrganisation(request: RemoveOrganisationRequest): Observable<any> {
    return this.api.post(LotsListAnnonceResponse, this.api.clearRequestParams(request), 'seal/organisation_remove');
  }

  getRectifications(req: GetRectificatioRequest): Observable<RectificationResponse> {
    return this.api.post(RectificationResponse, req, 'seal/rectification_list');
  }

  createUpdateRectification(request: EditAnnonceRectificationResquest): Observable<RectificationResponse> {    
    return this.api.post(RectificationResponse, this.api.clearRequestParams(request), 'seal/rectification_edit');
  }

  deleteRectification(request: RemoveRectificationRequest): Observable<any> {
    return this.api.post(LotsListAnnonceResponse, this.api.clearRequestParams(request), 'seal/rectification_remove');
  }

  editLots(request: EditLotRequest): Observable<LotsListAnnonceResponse> {
    return this.api.post(LotsListAnnonceResponse, request, 'seal/lots_edit');
  }

  editcritereSeclect(request: CritereSelectionRequest): Observable<CriteresSelectionListAnnonceResponse> {
    return this.api.post(CriteresSelectionListAnnonceResponse, request, 'seal/criteres_selection_edit');
  }

  deletecritereSeclect(request: DeleteCritereSelectRequest): Observable<MessageResponse> {
    return this.api.post(MessageResponse, request, 'seal/criteres_selection_remove');
  }

  deleteCritereAttribution(request: DeleteCritereAttributionRequest): Observable<MessageResponse> {
    return this.api.post(MessageResponse, request, 'seal/criteres_attribution_remove');
  }

  getCritereSelectionList(request: CritereSelectionListRequest): Observable<CriteresSelectionListAnnonceResponse> {
    return this.api.post(CriteresSelectionListAnnonceResponse, request, 'seal/criteres_selection_list');
  }

  getCritereAttributionList(request: CritereAttribRequest): Observable<CritereAttributionListAnnonceResponse> {
    return this.api.post(CritereAttributionListAnnonceResponse, request, 'seal/criteres_attribution_list');
  }

  editcritereAttrib(request: CritereAttribRequest): Observable<CritereAttributionListAnnonceResponse>{
    return this.api.post(CritereAttributionListAnnonceResponse, request, 'seal/criteres_attribution_edit');
  }

  getParties(req: GetPartieRequest) {
    return this.api.post(PartResponse, req, 'seal/part_list');
  }

  editPartie(req: EditPartieRequest) {
    return this.api.post(PartItem, req, 'seal/part_edit');
  }

  deletePartie(req: DeletePartieRequest) {
    return this.api.post(DefaultResponse, req, 'seal/part_remove');
  }
  
}
