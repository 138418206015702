import { AncChorusComponent } from '../../components/anc-chorus/anc-chorus.component';
import { AncDepartmentPrestationComponent } from '../../components/anc-department-prestation/anc-department-prestation.component';
import { AncFacturationAdressComponent } from '../../components/anc-facturation-adress/anc-facturation-adress.component';
import { AncInfoComponent } from '../../components/anc-info/anc-info.component';
import { AncOptionEnvoiComponent } from '../../components/anc-option-envoi/anc-option-envoi.component';
import { FormDisplay } from '../../../../../../core/models/pa/annonce/form-display.model';
import { AncTypePaComponent } from '@atline/marches-securises/pa/edition/edition-annonce/components/anc-type-pa/anc-type-pa.component';
import { AncPrincipalActivityComponent } from '@atline/marches-securises/pa/edition/edition-annonce/components/anc-principal-activity/anc-principal-activity.component';
import { AncLegalBasisProcedureComponent } from '@atline/marches-securises/pa/edition/edition-annonce/components/anc-legal-basis-procedure/anc-legal-basis-procedure.component';
import { AncAdditionalInfosExecussionPlaceComponent } from '@atline/marches-securises/pa/edition/edition-annonce/components/anc-additional-infos-execussion-place/anc-additional-infos-execussion-place.component';
import { AncLotsComponent } from '../../components/anc-lots/anc-lots.component';
import { AncOrganisationEformComponent } from '../../components/anc-organisation-eform/anc-organisation-eform.component';
import { AncProcedureComponent } from '../../components/anc-procedure/anc-procedure.component';
import { AncDroitsTransfComponent } from '../../components/anc-droits-transf/anc-droits-transf.component';
import { AncDataContactComponent } from '../../components/anc-data-contact/anc-data-contact.component';
import { AncReferencePubComponent } from '../../components/anc-reference-pub/anc-reference-pub.component';
import { AncAutreBaseJuridiqueComponent } from '../../components/anc-autre-base-juridique/anc-autre-base-juridiquecomponent';
import { AncBaseSansIdentifiantComponent } from '../../components/anc-base-sans-identifiant/anc-base-sans-identifiant.component';
import { AncDescriptionBaseJuridiqueComponent } from '../../components/anc-description-base-juridique/anc-description-base-juridique.component';
import { AncObjetMarcheComponent } from '../../components/anc-objet-marche/anc-objet-marche.component';
import { AncTvaMarcheComponent } from '../../components/anc-tva-marche/anc-tva-marche.component';
import { AncConditionsContratsComponent } from '../../components/anc-conditions-contrats/anc-conditions-contrats.component';
import { AncInformationsComplComponent } from '../../components/anc-informations-compl/anc-informations-compl.component';
import { AncDivisionLotsComponent } from '../../components/anc-division-lots/anc-division-lots.component';
import { AncSoumissionnairesComponent } from '../../components/anc-soumissionnaires/anc-soumissionnaires.component';
import { AncMarchesComponent } from '../../components/anc-marches/anc-marches.component';
import { AncOffresComponent } from '../../components/anc-offres/anc-offres.component';
import { AncResultsLotsComponent } from '../../components/anc-results-lots/anc-results-lots.component';
import { AncResultComponent } from '../../components/anc-result/anc-result.component';
import { AncCpvClassificationComponent } from '../../components/anc-cpv-classification/anc-cpv-classification.component';

const eform_29: FormDisplay = {
  0: {
    id: 'pub',
    text: 'APP.ANNONCES.DISPLAYS.EFORM_29.0.TEXT',
    title: 'APP.ANNONCES.DISPLAYS.EFORM_29.0.TITLE',
    blocs: {
      bloc1: {
        title: 'APP.ANNONCES.DISPLAYS.EFORM_29.0.BLOCKS.1.TITLE',
        expansion: true,
        comps: [
          {
            component: AncOptionEnvoiComponent,
            required: {
              compteBoamp: true,
              destinataire: true,
              langue: true,
            },
            composition: {
              destinataire: true,
              compteBoamp: true,
              publish: true,
              langue: true
            }
          }
        ]
      },
      bloc2: {
        title: 'APP.ANNONCES.DISPLAYS.EFORM_29.0.BLOCKS.2.TITLE',
        expansion: true,
        comps: [
          {
            component: AncDepartmentPrestationComponent,
            required: {
              defaultDepartement: true,
              otherDepartment: false,
              info: true
            },
            composition: {
              defaultDepartement: true,
              otherDepartment: true,
              info: true
            }
          }
        ]
      },
      // bloc3: {
      //   title: 'APP.ANNONCES.DISPLAYS.EFORM_29.0.BLOCKS.3.TITLE',
      //   expansion: true,
      //   comps: [
      //     {
      //       component: AncSupportFacultatifMessageComponent,
      //       required: {
      //         message: false
      //       },
      //       composition: {
      //         message: true
      //       }
      //     },
      //     {
      //       component: AncPublicationSupportComponent,
      //       required: {
      //         list: false
      //       },
      //       composition: {
      //         list: true
      //       }
      //     }
      //   ]
      // }
    }
  },
  1: {
    title: 'APP.ANNONCES.DISPLAYS.EFORM_29.1.TITLE',
    text: "APP.ANNONCES.DISPLAYS.EFORM_29.1.TITLE",
    blocs: {
      bloc1: {
        title: 'APP.ANNONCES.DISPLAYS.EFORM_29.1.BLOCKS.1.TITLE',
        comps: [
          {
            component: AncOrganisationEformComponent,
            required: {
              name: true,
              department: false,
              siret: true,
              country: true,
              ville: true,
              mel: true
            },
            composition: {
              name: true,
              department: true,
              siret: true,
              contact: true,
              address1: true,
              address2: true,
              address3: true,
              cp: true,
              ville: true,
              country: true,
              phone: true,
              fax: true,
              mel: true,
              url: true,
              exchangeUrl: true,
              isPhysicalPeople: true,
              sizeOe: false,
              laureat: true,
              chefDeFil: true,
              central1: true,
              central2: true
            }
          }
        ],
      }
    }
  },
  2: {
    title: 'Parties contractantes et prestataire de services',
    text: "Parties contractantes et prestataire de services",
    blocs: {
      bloc1: {
        title: 'Acheteur',
        comps: [
          {
            component: AncDataContactComponent,
            required: {
              typePA: true,
              autreOrguanisme: true
            },
            composition: {
              nameOrganisation: true,
              contactPoint: true,
              departement: true,
              street: {
                name: true,
                additional: true,
                line: true
              },
              zipcode: true,
              country: true,
              codeNUTS: true,
              siret: true,
              phone: true,
              fax: true,
              mail: true,
              webSite: true,
              webSitePA: true,
            }
          }
        ]
      },
      bloc2: {
        title: 'Acheteur',
        comps: [
          {
            component: AncTypePaComponent,
            required: {
              typePA: true,
              autreOrguanisme: true
            },
            composition: {
              typePA: true,
              autreOrguanisme: true
            }
          },
          {
            component: AncPrincipalActivityComponent,
            required: {
              activities: true
            },
            composition: {
              activities: true
            }
          }
        ]
      }
    }
  },
  3: {
    title: 'Procédure',
    text: "Procédure",
    tabs: {
      tab1: {
        title: 'Objet',
        blocs: {
          bloc1: {
            title: 'Référence de l\'avis précédent (GR-Previous-Notice)',
            comps: [
              {
                component: AncReferencePubComponent,
                composition: { previousTender: true },
                required: { previousTender: false },
              }
            ]
          },
          bloc2: {
            title: 'Base juridique (GR-Procedure-MainLegalBasis)',
            comps: [
              {
                component: AncLegalBasisProcedureComponent,
                composition: { legalBasisProcedure: true },
                required: { legalBasisProcedure: true },
              }
            ]
          },
          bloc3: {
            title: 'Autre base juridique avec un identifiant (GR-Procedure-LocalLegalBasisWithID)',
            comps: [
              {
                component: AncAutreBaseJuridiqueComponent,
                composition: { infoData: true, selectBase: true },
                required: { infoData: false, selectBase: false },
              }
            ]
          },
          bloc4: {
            title: 'Autre base juridique sans identifiant connu (GR-Procedure-LocalLegalBasisNoID)',
            comps: [
              {
                component: AncBaseSansIdentifiantComponent,
                composition: { infoData: true },
                required: { infoData: false },
              },
            ]
          },
          bloc5: {
            title: 'Description (GR-Procedure-Description)',
            comps: [
              {
                component: AncDescriptionBaseJuridiqueComponent,
                composition: { identifiantInterne: true, titre: true, natureContract: true, infoData: true },
                required: { identifiantInterne: false, titre: true, natureContract: true, infoData: false },
              },
              {
                component: AncObjetMarcheComponent,
                composition: { intitule: true },
                required: { intitule: false },
              },
            ]
          },
          bloc6: {
            title: 'Étendue du marché (GR-Procedure-Scope)',
            comps: [
              {
                component: AncTvaMarcheComponent,
                composition: { value: true, currency: true, valuemax: true },
                required: { value: false, currency: false, valuemax: false },
              },
              {
                component: AncCpvClassificationComponent,
                composition: { principal: true, cpv: true, cpv1: true, cpv2: true, cpv3: true },
                required: { principal: true, cpv: true, cpv1: true, cpv2: true, cpv3: true }
              }
            ]
          },
          bloc7: {
            title: 'Informations supplémentaires sur le lieu d’exécution (GR-Procedure-PlaceOfPerformance)',
            comps: [
              {
                component: AncAdditionalInfosExecussionPlaceComponent,
                composition: { adr1: true, adr2: true, adr3: true, country: true, nuts: true, cp: true, city: true, restrict: true, infos_comp_marche: true, infos_comp_procedure: true },
                required: { adr1: false, adr2: false, adr3: false, country: false, nuts: false, cp: false, city: false, restrict: false, infos_comp_marche: false, infos_comp_procedure: false },
              },
            ]
          }
        }
      },
      tab2: {
        title: 'Conditions de passation du marché',
        blocs: {
          bloc1: {
            title: 'Motifs d’exclusion (GR-Procedure-ExclusionGrounds)',
            comps: [
              {
                component: AncConditionsContratsComponent,
                composition: {
                  codeNational: true,
                  codeFaillite: true,
                  codeCorruption: true,
                  codeCreanciers: true,
                  codeOrg: true,
                  codeDistorsion: true,
                  codeEnv: true,
                  codeBlanchiment: true,
                  codeFraude: true,
                  codeTraf: true,
                  codeInsolv: true,
                  codeTravail: true,
                  codeLiquid: true,
                  codeFausse: true,
                  codeNationaux: true,
                  codeConflits: true,
                  codePrep: true,
                  codeFaute: true,
                  codeSanction: true,
                  codeOblig: true,
                  codePay: true,
                  codeActiv: true,
                  codeImpots: true,
                  codeInter: true,
                },
                required: {
                  codeNational: true,
                  codeFaillite: true,
                  codeCorruption: true,
                  codeCreanciers: true,
                  codeOrg: true,
                  codeDistorsion: true,
                  codeEnv: true,
                  codeBlanchiment: true,
                  codeFraude: true,
                  codeTraf: true,
                  codeInsolv: true,
                  codeTravail: true,
                  codeLiquid: true,
                  codeFausse: true,
                  codeNationaux: true,
                  codeConflits: true,
                  codePrep: true,
                  codeFaute: true,
                  codeSanction: true,
                  codeOblig: true,
                  codePay: true,
                  codeActiv: true,
                  codeImpots: true,
                  codeInter: true,
                }
              }
            ]
          },
          bloc2: {
            title: 'Droit transfrontière (GR-Procedure-CrossBorderLaw)',
            comps: [
              {
                component: AncDroitsTransfComponent,
                composition: {

                }
              }
            ]
          }
        }
      },
      tab3: {
        title: 'Procédure',
        blocs: {
          bloc1: {
            title: 'Procédure (GR-Procedure-Procedure)',
            comps: [
              {
                component: AncProcedureComponent,
                composition: {
                  description: true,
                  procedure: true,
                  relanceInfructeux: true,
                },
                required: {
                  description: false,
                  procedure: true,
                  relanceInfructeux: false,
                }
              }
            ]
          },
          bloc2: {
            title: 'Informations complémentaires (GR-Procedure-AdditionalInformation)',
            comps: [
              {
                component: AncInformationsComplComponent,
                composition: {
                  description: true,
                },
                required: {
                  description: false,
                }
              }
            ]
          },
          // bloc2: {
          //   title: 'Accelerated Procedure (or not) and Justification (GR-Procedure-Accelerated)',
          //   comps: [
          //     {
          //       component: AncProcedureAccelereeComponent,
          //       composition: {
          //         delai_urgence: true,
          //         description: true,
          //       },
          //       required: {
          //         delai_urgence: true,
          //         description: false
          //       }
          //     }
          //   ]
          // },
        }
      },
      tab4: {
        title: 'Distribution des lots',
        blocs: {
          bloc1: {
            title: 'Nombre maximal de lots (GR-Procedure-MaxLots)',
            comps: [
              {
                component: AncDivisionLotsComponent,
                required: {
                  value: true,
                  valuemax: false,
                  repartitionLots: false,
                },
                composition: {
                  value: true,
                  valuemax: true,
                  repartitionLots: true,
                }
              }
            ]
          }
        }
      }
    },
  },
  4: {
    title: 'Lot(s)',
    text: "Lot(s)",
    blocs: {
      blocs1: {
        title: 'Mes Lots',
        comps: [
          {
            component: AncLotsComponent,
            composition: {
              elecauction:false,
              programFC:false,
              identifiantFC: false,
              infosFC: false,
              nbrCandidatFC: false,
              justifFC: false,
              stage_elimFC: false,
              displayAwardingConsequences: false,
              juridiqueGroupFC:false,
              participationResvFC:false,
              variantesFC:false,
              recrrentApprovFC:false,
              execmarchFC:false,
              facturationFC: false,
              nomqualificationsFC: false,
              securiteFC: false,
              accordDivulgationFC: false,
              catalogElecFC: false,
              cachetElecFC: false,
              cammandeElecFC: false,
              arrangementFC: false,
              destinataireOffreFC: false,
              evalueateurFC: false,
              information : false,
              dateRecepFC: false,
              typeValidateFC: false,
              dateOuvertureFC: false,
              garantieFC: false,
              soumissionElecFC: false,
              nomSoumissFC: false
            },
            required: {
              elecauctionFC:false,
              programFC:false,
              identifiantFC: false,
              infosFC: false,
              nbrCandidatFC: false,
              justifFC: false,
              stage_elimFC: false,
              displayAwardingConsequences: false,
              juridiqueGroupFC:false,
              participationResvFC:false,
              variantesFC:false,
              recrrentApprovFC:false,
              execmarchFC:false,
              facturationFC: false,
              nomqualificationsFC: false,
              securiteFC: false,
              accordDivulgationFC: false,
              catalogElecFC: false,
              cachetElecFC: false,
              cammandeElecFC: false,
              arrangementFC: false,
              destinataireOffreFC: false,
              evalueateurFC: false,
              dateRecepFC: false,
              typeValidateFC: false,
              dateOuvertureFC: false,
              garantieFC: false,
              soumissionElecFC: false,
              nomSoumissFC: false
            }
          }
        ]
      }
    }
  },
  5: {
    title: 'Résultat',
    text: "Résultat",
    tabs: {
      tab1: {
        title: 'Résultat',
        blocs: {
          bloc1: {
            title: 'Résultats',
            comps: [
              {
                component: AncResultComponent,
                composition: {
                  valMarchesAtt: true,
                  valMaxAccord: true,
                  valApproxAccord: true,
                  currency: true
                }
              }
            ]
          }
        }
      },
      tab2: {
        title: 'Soumissionnaires',
        blocs: {
          bloc1: {
            title: 'Mes soumissionnaires',
            comps: [
              {
                component: AncSoumissionnairesComponent
              }
            ]
          }
        }
      },
      tab3: {
        title: 'Marchés',
        blocs: {
          bloc1: {
            title: 'Mes marchés',
            comps: [
              {
                component: AncMarchesComponent
              }
            ]
          }
        }
      },
      tab4: {
        title: 'Offres',
        blocs: {
          bloc1: {
            title: 'Mes offres',
            comps: [
              {
                component: AncOffresComponent
              }
            ]
          }
        }
      },
      tab5: {
        title: 'Résultats des lots',
        blocs: {
          bloc1: {
            title: 'Mes résultats de lots',
            comps: [
              {
                component: AncResultsLotsComponent
              }
            ]
          }
        }
      },
    }
  },
  6: {
    id: 'fac',
    title: 'APP.ANNONCES.DISPLAYS.EFORM_29.9.TITLE',
    text: '€',
    blocs: {
      bloc1: {
        title: 'APP.ANNONCES.DISPLAYS.EFORM_29.5.BLOCKS.1.TITLE',
        comps: [
          {
            component: AncInfoComponent,
            translateKey: 'FACTURATION',
            etat: 'warning',
            required: {},
            composition: {}
          },
          {
            component: AncChorusComponent,
            required: {
              siret: false,
              tvaIntra: false,
              engagement: false
            },
            composition: {
              siret: true,
              tvaIntra: true,
              engagement: true
            }
          }
        ]
      },
      bloc2: {
        title: 'APP.ANNONCES.DISPLAYS.EFORM_29.5.BLOCKS.2.TITLE',
        comps: [{
          component: AncFacturationAdressComponent,
          required: {
            profil: false,
            name: false,
            recipant: true,
            building: true,
            road: true,
            special: true,
            cp: true,
            city: true

          },
          composition: {
            profil: false,
            name: true,
            recipant: true,
            building: true,
            road: true,
            special: true,
            cp: true,
            city: true
          }
        }]
      }
    }
  }
};

export default eform_29;
